@import '../../../node_modules/react-perfect-scrollbar/dist/css/styles.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

html {
	height: 100%;
}

#__next {
	height: 100%;
}

html,
body {
	overscroll-behavior-y: none;
}

@layer components {
	.h {
		@apply font-display font-semibold;
	}
	.p {
		@apply font-body;
	}
	.a {
		@apply text-medblue hover:underline;
	}
	.btn,
	.btn-sm,
	.btn-md,
	.btn-lg {
		@apply font-display font-semibold min-h-0 inline-flex align-middle tracking-wide;
	}

	.btn-reset {
		@apply border-none shadow-none cursor-pointer;
	}

	.mapContainer {
		@apply top-0 left-0 right-0 bottom-0 h-full w-full absolute pointer-events-none;
	}

	.centered {
		@apply flex justify-center items-center;
	}
	.playertag {
		@apply text-[10px] bg-opacity-85 font-semibold w-fit px-2 shrink-0 text-center rounded-2xl;
	}
	.homePageSlider {
		@apply rounded-xl mx-2 bg-gradient-to-r p-[4px] from-[#B9E769] via-medblue to-[#00A19A];
	}
	.homePageSliderContent {
		@apply flex flex-col justify-center h-full bg-gradient-to-t from-[#f7fafc] to-white rounded-lg p-5 my-auto self-center;
	}

	/* Srollbar for virtual list */
	.virtual-scroller {
		scrollbar-width: thin !important;
		scrollbar-color: #d6dee1 transparent !important;
	}

	/* Scrollbar for main app */
	* {
		scrollbar-width: thin;
		scrollbar-color: #00a3b8 #d6dee1;
	}
	*::-webkit-scrollbar {
		width: 8px;
	}
	*::-webkit-scrollbar-track {
		background-color: #d6dee1;
	}
	*::-webkit-scrollbar-thumb {
		background-color: #00a3b8;
		border-radius: 20px;
		background-clip: content-box;
	}

	.heroImage {
		background-position: 50% 100%;
		background-size: 100% 100%;
		background-size: cover;
		position: relative;
	}

	/*ShadowText used in Game Logo*/
	.shadowtext {
		text-shadow: 0 1px 0 hsl(212, 27%, 70%), 0 2px 0 hsl(212, 27%, 55%),
			0 3px 0 hsl(212, 27%, 45%), 0 4px 0 hsl(212, 27%, 30%), 0 0 5px rgba(0, 0, 0, 0.05),
			0 1px 3px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.2),
			0 5px 10px rgba(0, 0, 0, 0.2), 0 7px 7px rgba(0, 0, 0, 0.2);
	}

	/*Shine button used in HomePage*/
	.shiningbutton {
		position: relative;
		overflow: hidden;
	}
	.shiningbutton:after {
		content: '';
		position: absolute;
		top: -50%;
		right: -5%;
		bottom: -50%;
		left: -5%;
		background: linear-gradient(
			to bottom,
			rgba(229, 172, 142, 0),
			rgba(255, 255, 255, 0.5) 50%,
			rgba(229, 172, 142, 0)
		);
		transform: rotateZ(120deg) translate(1em, 12em);
		animation-duration: 8s;
		animation-timing-function: linear;
		animation-name: shinebutton;
		animation-iteration-count: infinite;
		animation-direction: forward;
	}
	@keyframes shinebutton {
		90% {
			transform: rotateZ(120deg) translate(1em, 12em);
		}
		100% {
			transform: rotateZ(120deg) translate(1em, -12em);
		}
	}

	/*Shine card used in MembershipCard*/
	.shiningcard {
		width: 400px;
		height: 100px;
		margin-left: -100px;
		-webkit-transform: rotate(30deg);
		-ms-transform: rotate(30deg);
		transform: rotate(30deg);
		background: -webkit-gradient(
			linear,
			left bottom,
			left top,
			from(transparent),
			color-stop(rgba(255, 255, 255, 0.5)),
			to(transparent)
		);
		background: -o-linear-gradient(bottom, transparent, rgba(255, 255, 255, 0.5), transparent);
		background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.5), transparent);
		position: absolute;
		-webkit-animation: shinecard 6s ease-in-out infinite;
		animation: shinecard 6s ease-in-out infinite;
	}
	@keyframes shinecard {
		0%,
		100% {
			margin-top: -100%;
		}
		50% {
			margin-top: 100%;
		}
	}

	.mixblend {
		mix-blend-mode: color-dodge;
		filter: brightness(0.66) contrast(1.33);
	}

	/*Radial Gradient for Essence Offering*/
	.essenceofferingGlow {
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(22, 219, 147, 1) 0%,
			rgba(22, 219, 147, 0) 100%
		);
		animation: offeringlow 2s ease-in-out infinite alternate;
	}
	.essenceofferingGlowUltra {
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(242, 158, 76, 1) 0%,
			rgba(242, 158, 76, 0) 100%
		);
		animation: offeringlow 2s ease-in-out infinite alternate;
	}
	@keyframes offeringlow {
		from {
			opacity: 1;
		}
		to {
			opacity: 0.5;
		}
	}

	/*Radial Gradient for Essence Offering*/
	.revealspiritGlow {
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(22, 219, 147, 1) 0%,
			rgba(22, 219, 147, 0) 100%
		);
	}
	.revealspiritGlowUltra {
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(242, 158, 76, 1) 0%,
			rgba(242, 158, 76, 0) 100%
		);
	}

	/*Outline Style Auto fix for MacOS radius*/
	.outlineproper {
		outline-style: auto;
	}

	/*Waiting dots used in UseList*/
	.waitingmove-dot {
		width: 4px;
		height: 4px;
		margin: 0 1px;
		border-radius: 50%;
		animation: waitingMove 2s infinite;
	}
	.waitingmove-dot:nth-child(1) {
		animation-delay: 0s;
	}
	.waitingmove-dot:nth-child(2) {
		animation-delay: 0.4s;
	}
	.waitingmove-dot:nth-child(3) {
		animation-delay: 0.8s;
	}
	.waitingHost:after {
		overflow: hidden;
		display: inline-block;
		vertical-align: bottom;
		animation: ellipsis steps(4, end) 2000ms infinite;
		content: '\2026'; /* ascii code for the ellipsis character */
		width: 0px;
	}
	@keyframes ellipsis {
		to {
			width: 1.25em;
		}
	}
	@-webkit-keyframes ellipsis {
		to {
			width: 1.25em;
		}
	}
	@keyframes waitingMove {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 0.8;
		}
		100% {
			opacity: 0;
		}
	}

	.container-default {
		@apply container relative mx-auto max-w-7xl px-6 sm:px-14;
	}

	@keyframes spinning-border {
		100% {
			transform: rotate(360deg);
		}
	}

	.sr-only {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
}

a {
	text-decoration: none;
	cursor: pointer;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus {
	outline: none;
}

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"]
{
	display: none !important;
}

.streetViewYo
	.gm-style
	[style*='position: absolute'][style*='bottom: 0'][style*='right: 0']
	.gmnoprint:nth-of-type(2) {
	display: none !important;
}

.constructiontape {
	border-top: solid 10px;
	border-bottom: solid 10px;
	border-image: repeating-linear-gradient(
			-55deg,
			#f1c453,
			#f1c453 20px,
			#4a5460 20px,
			#4a5460 40px
		)
		10;
}
